/* LoveLetters.css */
:root {
  --primary: #fff;
  --bg-notebook-color: #faf3e0;
  --notebook-line-color: #c8c8c8;
  --txt-color: #444;
  --shadow-color: rgba(0, 0, 0, 0.2);
  --spiral-color: #444;
}

.book {
  max-width: 500px;
  height: 400px;
  background: var(--bg-notebook-color);
  box-shadow: 0 0 20px var(--shadow-color);
  border-radius: 16px;
  position: relative;
  perspective: 1000px;
  overflow-y: auto; /* Allow scrolling */
  margin-bottom: 30px;
  padding-bottom: 60px; /* Space for navigation buttons */
}

.page {
  width: 100%;
  height: calc(100% - 10px); /* Leave space for navigation buttons */
  padding: 20px;
  box-sizing: border-box;
  border-radius: 16px;
  background: var(--bg-notebook-color);
  overflow-y: auto; /* Allow scrolling for long letters */
}


.page-content {
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  color: var(--txt-color);
  text-align: left;
  font-size: 14px;
  line-height: 1.5;
  overflow-y: auto;
}
    
.page-date, .page-from {
  margin: 0;
  padding: 5px 0;
}
    
.page-text {
  margin-top: 15px;
}
    
.letters-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 16px;
  box-shadow: 0 0 20px rgba(255, 182, 193, 0.5);
  max-width: 500px;
  margin: 20px auto;
  text-align: center;
  font-family: "Cursive", sans-serif;
  position: relative;
}

.spiral-notebook {
  position: relative;
  width: 300px;
  height: 350px;
  margin: 0 auto;
  perspective: 1000px;
}

.spiral-notebook::before {
  content: "";
  position: absolute;
  left: -20px;
  top: 0;
  height: 350px;
  width: 20px;
  background: var(--spiral-color);
  border-radius: 10px;
}

.notebook-page {
  position: absolute;
  width: 100%;
  height: 90%;
  background: var(--bg-notebook-color);
  border: 1px solid var(--notebook-line-color);
  box-shadow: 0 0 10px var(--shadow-color);
  padding: 20px;
  transform-origin: left;
  transition: transform 0.7s ease-in-out;
  overflow-y: auto;
}

.notebook-page:not(.active) {
  transform: rotateY(-180deg);
}

.text {
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  color: var(--txt-color);
  text-align: left;
  font-size: 14px;
  line-height: 1.5;
  max-height: 100%;
  overflow-y: auto;
}

.page-navigation {
  position: absolute; /* Fix to bottom of container */
  bottom: 10px; /* Align just above the bottom */
  right: 50%;
  transform: translateX(50%); /* Center horizontally */
  display: flex;
  gap: 10px;
  justify-content: center; /* Center the buttons */
}
  
  .nav-button {
  background-color: black;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 8px;
  font-family: "Cursive", sans-serif;
  font-size: 16px;
  }
  
.nav-button:disabled {
  background-color: #888;
  cursor: not-allowed;
}

/* Responsive Design for Mobile Screens */
@media (max-width: 768px) {
    .book {
        width: 90%;
        margin: 0 auto; /* Center horizontally */
        max-width: 500px; /* Ensure it doesn't get too wide */
        margin-bottom: 30px;
    }

    .letters-container {
      padding: 20px;
      width: 90%;
      max-width: 500px; /* Ensure the letters container also stays centered and even */
      margin: 20px auto; /* Add vertical margin to prevent crowding */
    }
}