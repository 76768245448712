/* Add to App.css */

@keyframes float {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-800px);
    opacity: 0;
  }
}

.heart {
  position: fixed;
  bottom: 0;
  width: 50px;
  height: 50px;
  background-color: #ff69b4;
  transform: rotate(-45deg);
  animation: float linear infinite;
  animation-duration: 10s;
}

.heart::before,
.heart::after {
  content: "";
  position: absolute;
  width: 50px;
  height: 50px;
  background-color: #ff69b4;
  border-radius: 50%;
}

.heart::before {
  top: -25px;
  left: 0;
}

.heart::after {
  top: 0;
  left: -25px;
}

/* Different animations for each heart */
.heart:nth-child(1) {
  left: 20%;
  animation-duration: 7s;
  animation-delay: 1s;
}

.heart:nth-child(2) {
  left: 40%;
  animation-duration: 5s;
  animation-delay: 3s;
}

.heart:nth-child(3) {
  left: 60%;
  animation-duration: 9s;
  animation-delay: 0s;
}

.heart:nth-child(4) {
  left: 80%;
  animation-duration: 6s;
  animation-delay: 2s;
}
/* Welcome message styling */
.welcome-message {
  font-family: "Cursive", sans-serif;
  font-size: 28px;
  color: #ff69b4;
  margin: 100px auto 20px; /* Increase the top margin for better positioning */
  text-align: center;
  text-shadow: 2px 2px 5px rgba(255, 182, 193, 0.8);
}

/* Style logout button and center it */
.logout-button-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.logout-button {
  background-color: #ff4b4b;
  color: white;
  border: none;
  padding: 8px 18px;
  cursor: pointer;
  border-radius: 15px;
  font-family: "Cursive", sans-serif;
  font-size: 16px;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.logout-button:hover {
  background-color: #ff6666;
  transform: scale(1.05);
}



/* Responsive design for mobile screens */
@media (max-height: 800px) {
  .welcome-message {
    font-size: 24px; /* Adjust font size for better visibility on mobile and medium screens */
    padding-top: 200px;
  }

  .app-container {
    padding: 40px 20px; /* Add more padding to ensure content doesn't touch edges */
  }

  .user-section {
    margin-top: 50px; /* Increase margin to provide more space between the welcome message and the next section */
  }

  .write-letter-container {
    margin-top: 20px; /* Add extra spacing below the welcome section */
  }
}