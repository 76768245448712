/* CSS: src/components/WriteLoveLetter.css */
.write-letter-container {
    background-color: #fff;
    padding: 20px;
    border-radius: 16px;
    box-shadow: 0 0 20px rgba(255, 182, 193, 0.5);
    max-width: 500px;
    margin: 20px auto;
    text-align: center;
    font-family: "Cursive", sans-serif;
  }
  
  .write-input {
    width: calc(100% - 20px);
    padding: 10px;
    margin: 10px 0;
    border-radius: 10px;
    border: 1px solid #ffb6c1;
  }
  
  .write-textarea {
    width: calc(100% - 20px);
    height: 100px;
    padding: 10px;
    margin: 10px 0;
    border-radius: 10px;
    border: 1px solid #ffb6c1;
  }
  
  .write-button {
    background-color: #ff69b4;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 10px;
    margin-top: 10px;
    font-family: "Cursive", sans-serif;
  }
  
  .write-button:hover {
    background-color: #ff1493;
  }

  /* Responsive Design for Mobile Screens */
@media (max-width: 768px) {
  .write-letter-container {
    padding: 30px 15px; /* Add more padding for smaller screens */
    margin: 20px; /* Ensure even spacing on smaller screens */
  }

  .write-input,
  .write-textarea {
    width: calc(100% - 30px); /* Adjust to fit within new padding */
  }
}