/* CSS: src/components/Auth.css */
.auth-container {
  background-color: #fff;
  padding: 40px;
  border-radius: 16px;
  box-shadow: 0 0 40px rgba(255, 182, 193, 0.7);
  max-width: 500px;
  margin: 40px auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid #ff1493;
}

.auth-input {
  width: calc(100% - 20px);
  padding: 15px;
  margin: 15px 0;
  border-radius: 10px;
  border: 1px solid #ffb6c1;
}

.auth-button {
  background-color: #ff69b4;
  color: white;
  border: none;
  padding: 15px 30px;
  cursor: pointer;
  border-radius: 10px;
  margin-top: 20px;
  font-family: "Cursive", sans-serif;
  font-size: 18px;
}

.auth-button:hover {
  background-color: #ff85b5;
}

.switch-button {
  background-color: transparent;
  color: #ff69b4;
  border: none;
  cursor: pointer;
  margin-top: 20px;
  font-family: "Cursive", sans-serif;
  font-size: 16px;
}

.switch-button:hover {
  color: #ff1493;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
}

@media (max-width: 500px) {
  .auth-container, .write-letter-container, .letters-container {
      box-shadow: 0 0 10px rgba(255, 182, 193, 0.3);
  }
}

@media (max-width: 500px) {
  .auth-container, .write-letter-container, .letters-container {
      max-width: 100%;
      padding: 10px;
      margin: 10px auto;
  }

  .auth-button, .write-button, .nav-button {
      padding: 8px 15px;
      font-size: 14px;
  }

  .write-input, .write-textarea, .auth-input {
      padding: 8px;
      margin: 8px 0;
  }
}
